import ProfilePicture from './assets/img/profile-pic.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './assets/css/App.css'

function App() {
  return (
    <div className="App App-header">
      <div className="presentation">
      <img className="img-circle profile-picture" src={ProfilePicture} alt="Eric"/>
        
        <div className="info">
          <h1 className="text name">Eric Gottschalk</h1>
          <p className="text"><code>Software Engineer</code></p> 
        </div>
      </div>
      
      <footer>
        <div className="socials">    
            <a className="social-icon" target="__blank" href="https://www.linkedin.com/in/eric-gottschalk"><FontAwesomeIcon icon={faLinkedin}/></a>
            <a className="social-icon" target="__blank" href="https://github.com/ericgottschalk"><FontAwesomeIcon icon={faGithub} /></a>
            <a className="social-icon" target="__blank" href="https://www.instagram.com/ericgottsch4lk/"><FontAwesomeIcon icon={faInstagram}/></a>
        </div>
        <p className="text"><FontAwesomeIcon icon={faEnvelope}/>&nbsp;&nbsp;contact@ericgottschalk.net</p>
        <p className="text"><FontAwesomeIcon icon={faLocationDot}/>&nbsp;&nbsp;Brazil</p>
      </footer>
    </div>
  );
}

export default App;